import React from "react"

import Loader from "./components/Loader"
import Router from "./router"

class App extends React.Component {
  state = {
    siteHasLoaded: false,
  }

  async componentDidMount() {
    this.setState({ siteHasLoaded: true })

    const item = localStorage.getItem("language")

    if (!item) {
      localStorage.setItem("language", "pl")
    }
  }

  render() {
    const { siteHasLoaded, currentlyLoading } = this.state

    if (!siteHasLoaded) return <Loader text={currentlyLoading} />
    return <Router />
  }
}

export default App

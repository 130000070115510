import React from "react"
import translator from "../../assets/translations/translator"
import * as Icons from "../../assets/icons"


const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer-item'>
        <div className='footer-item-text'>
          {localStorage.getItem("language") === "pl" ?
            <React.Fragment>
              <b>{translator("client_office", "uppercase")}</b>
              <a>Aleksander Ostapiuk</a>
              <a>Q-GUAR Sp. z o.o.</a>
              <a href='mailto:biuro@q-guar.com'>
                biuro@q-guar.com
              </a>
              <a href='tel:+48603566118 '>
                {translator("tel", "capitalize_first")}. +48 603 566 118
              </a>
            </React.Fragment>
            :
            <React.Fragment>
              <b>{translator("client_office", "uppercase")}</b>
              <a>Marcin Bogowski</a>
              <a>Q-GUAR Sp. z o.o.</a>
              <a href='mailto:marcin@q-guar.com'>
                marcin@q-guar.com
              </a>
              <a href='tel:+48883236080'>
                {translator("tel", "capitalize_first")}. +48 883 236 080
              </a>
              <a href='tel:+353833973952'>
                {translator("tel", "capitalize_first")}. +353 83 397 3952
              </a>
            </React.Fragment>
          }
        </div>
        <div className='footer-item-icon'>
          <Icons.Timer />
        </div>
      </div>
      <div className='footer-item'>
        <div className='footer-item-text'>
          <b>{translator("range_of_activities", "uppercase")}:</b>
          <p>
            {translator("text_range_of_activities", "capitalize_first")}
          </p>
        </div>
        <div className='footer-item-icon'>
          <Icons.Menu />
        </div>
      </div>
      <div className='footer-item'>
        <div className='footer-item-text'>
          <b>Q-GUAR SP. ZO.O.</b>
          ul.Duńska 9,<br />
          54-427 Wrocław<br />
          NIP 8943139879<br />
          KRS 0000778760
        </div>
        <div className='footer-item-icon'>
          <Icons.Home />
        </div>
      </div>
    </footer>
  )
}

export default Footer

import defaultTranslations from "./data_en"
import polishTranslations from "./data_pl"
import germanTranslations from "./data_de"

const translator = (item, casing = "lowercase") => {
  const translations = []

  const lang = localStorage.getItem("language")

  let string = defaultTranslations[item] ||
    defaultTranslations["unknown"]

  switch (lang) {
    case "pl":
      string = polishTranslations[item] ||
        polishTranslations["unknown"]
      break
    case "de":
      string = germanTranslations[item] ||
        germanTranslations["unknown"]
      break
  }


  switch (casing) {
    case "lowercase":
      return string.toLowerCase()
    case "capitalize_first":
      return string.charAt(0).toUpperCase() + string.slice(1)
    case "uppercase":
      return string.toUpperCase()
    default:
      return string
  }
}

export default translator

import React from "react"

export default class FacebookPanelTall extends React.Component {
  componentDidMount() {
    let allCorrect

    setTimeout(() => {
      allCorrect = document.getElementsByTagName("blockquote").length === 0
      if (!allCorrect) window.location.reload()
    }, 1000)
  }

  render() {
    const pl_url = "https://www.facebook.com/Q-Guar-Innowacje-100822171909745"
    const en_url = "https://www.facebook.com/Q-guar-106562914754546"
    const url = localStorage.getItem("language") === "pl" ? pl_url : en_url

    return (
      <div
        className="fb-page"
        data-href={url}
        data-tabs="timeline,messages"
        data-width=""
        data-height=""
        data-small-header="false"
        data-adapt-container-width="true"
        data-hide-cover="false"
        data-show-facepile="true"
      >
        <blockquote
          cite={url}
          class="fb-xfbml-parse-ignore"
        >
          <a href={url}>
            Q-Guar
          </a>
        </blockquote>
      </div>
    )
  }
}

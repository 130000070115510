import React from "react"

import Page from "../components/Page"
import ContactForm from "../components/ContactForm"
import translator from "../assets/translations/translator"

const Contact = () => (
  <Page title={translator("contact")}>
    <h2 className="site-title">{translator("contact")}</h2>
    <ContactForm />
  </Page>
)

export default Contact

import React, { useState } from "react"

import Page from "../components/Page"
import translator from "../assets/translations/translator"
import homeImage from "../assets/images/home-page-image.jpg"
import images from "../assets/images/slider"
import h1 from "../assets/images/h1.jpg"
import h2 from "../assets/images/h2.jpg"
import h3 from "../assets/images/h3.jpg"
import h4 from "../assets/images/h4.jpg"
import h5 from "../assets/images/h5.jpg"
import h6 from "../assets/images/h6.jpg"
import h7 from "../assets/images/h7.jpg"
import HomeRightSectionLinks from "../components/HomeRightSectionLinks"
import Paragraph from "../components/Site/Paragraph"
import { NavLink } from "react-router-dom"

const Home = () => {
  const image = images[Math.floor(Math.random() * images.length)].url

  return (
    <Page title={translator("home")}>
      <h2 className="site-title">{translator("our_offer")}</h2>
      <div className='home-sections'>
        <div className='left-section'>
          <img src={homeImage} alt={"main page"} className='home-page-image' />
          <Paragraph text={translator("home_1", "other")} image={h1} left />
          <Paragraph text={translator("home_2", "other")} image={h2} />
          <Paragraph text={translator("home_3", "other")} image={h3} left />
          <Paragraph text={translator("home_4", "other")} image={h4} />
          <Paragraph text={translator("home_5", "other")} image={h5} left />
          <Paragraph text={translator("home_6", "other")} image={h6} />
          <Paragraph text={translator("home_7", "other")} image={h7} left />
          <NavLink to='/contact'>
            <div className='button-contact-us'>
              {translator("contact_us", "uppercase")}
            </div>
          </NavLink>
        </div>
        <div className='right-section'>
          <div className='right-section-wrapper'>
            <h2 style={{ textAlign: "center", fontSize: "20px" }}>{translator("example_image", "capitalize_first")}</h2>
            <NavLink to={"/gallery"}>
              <img src={image} className='home-page-slider-2' />
            </NavLink>
            <HomeRightSectionLinks />
          </div>
        </div>
      </div>
    </Page>
  )
}

export default Home

import React from "react"
import { BrowserRouter, Route, Switch } from "react-router-dom"

import routes from "./data"
import Page404 from "../pages/Page404"

const Router = () => (
  <BrowserRouter>
    <Switch>
      {routes.map((route) => (
        <Route
          path={route.path}
          key={route.id}
          exact={route.exact}
          render={() => route.render}
        />
      ))}
      <Route render={() => <Page404 />} />
    </Switch>
  </BrowserRouter>
)

export default Router

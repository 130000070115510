import React from "react"

function createMarkup(text) {
  return { __html: text }
}

const Paragraph = ({ text, image, left }) => (
  <div className={`para-wrapper ${left ? "left" : "right"}`}>
    <p className="paragraph" dangerouslySetInnerHTML={createMarkup(text)}></p>
    {image && <img src={image} />}
  </div>
)

export default Paragraph

import React from "react"

import Page from "../components/Page"
import translator from "../assets/translations/translator"
import ContactForm from "../components/ContactForm"

function createMarkup(text) {
  return { __html: text }
}

const AboutUs = () => (
  <Page title={translator("about_us", "other")}>
    <h2 className="site-title">{translator("about_us", "other")}</h2>
    <div className='about-us-page'>
      <p className={"other-wrapper"}
         dangerouslySetInnerHTML={
           createMarkup(translator("about_us_text_1", "other"))
         }
      >
      </p>
      <p className={"other-wrapper"}
         dangerouslySetInnerHTML={
           createMarkup(translator("about_us_text_2", "other"))
         }
      >
      </p>
      <p className={"other-wrapper"}
         dangerouslySetInnerHTML={
           createMarkup(translator("about_us_text_3", "other"))
         }
      >
      </p>
      <p className={"other-wrapper"}
         dangerouslySetInnerHTML={
           createMarkup(translator("about_us_text_4", "other"))
         }
      >
      </p>
      <p className={"other-wrapper"}
         dangerouslySetInnerHTML={
           createMarkup(translator("about_us_text_5", "other"))
         }
      >
      </p>
      <p className={"other-wrapper"}
         dangerouslySetInnerHTML={
           createMarkup(translator("about_us_text_6", "other"))
         }
      >
      </p>
    </div>
    <h2 className="site-title">{translator("contact_us", "capitalize_first")}</h2>
    <ContactForm />
  </Page>
)

export default AboutUs

const mail = async (mail, address, file = null) => {

  const form = new FormData()
  form.append("mail", mail)
  form.append("file", file)
  form.append("address", address)

  await fetch("/api/mailer.php",
    {
      method: "POST",
      body: form,
    }).then(res => window.location.assign("/"))
    .catch(err => console.error(err))
}

export default mail

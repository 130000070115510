import React from "react"

import mailer from "../database/mail"
import translator from "../assets/translations/translator"
import FacebookPanelTall from "./FacebookPanelTall"
import cfImage from "../assets/images/contact-form-bg.jpg"

export default class ContactForm extends React.Component {
  state = {
    name: "",
    nameError: { on: false, text: "" },
    lastname: "",
    lastnameError: { on: false, text: "" },
    companyName: "",
    companyNameError: { on: false, text: "" },
    email: "",
    emailError: { on: false, text: "" },
    msg: "",
    msgError: { on: false, text: "" },
  }

  handleOnChange = (event) => {
    if (event.currentTarget.value === "")
      this.setState({
        [event.currentTarget.name]: event.currentTarget.value,
        [`${event.currentTarget.name}Error`]: {
          on: true,
          text: "- nie może być puste",
        },
      })
    else
      this.setState({
        [event.currentTarget.name]: event.currentTarget.value,
        [`${event.currentTarget.name}Error`]: { on: false },
      })
  }

  handleSubmit = async (event) => {
    event.preventDefault()
    const { name, lastname, companyName, email, msg } = this.state
    let error = false

    if (name === "") {
      error = true
      this.setState({
        nameError: { on: true },
      })
    }
    if (lastname === "") {
      error = true
      this.setState({
        lastnameError: { on: true },
      })
    }
    if (companyName === "") {
      error = true
      this.setState({
        companyNameError: { on: true },
      })
    }
    if (email === "") {
      error = true
      this.setState({
        emailError: { on: true },
      })
    }
    if (msg === "") {
      error = true
      this.setState({
        msgError: { on: true },
      })
    }

    if (error) return

    const address = localStorage.getItem("language") === "pl" ? "biuro@q-guar.pl" : "marcin@q-guar.pl"
    let mail = `
    Od: ${name} ${lastname}
    Adres email:${email}
    Nazwa firmy: ${companyName}
    
    Wiadomość:
    ${msg}`

    await mailer(mail, address)
  }

  render() {
    const {
      name,
      nameError,
      lastname,
      lastnameError,
      companyName,
      companyNameError,
      email,
      emailError,
      msg,
      msgError,
    } = this.state

    return (
      <div className='contact-page-wrapper'>
        <div className='facebook-wrapper'>
          <FacebookPanelTall />
        </div>
        <form onSubmit={this.handleSubmit} className="contact-form" style={{ backgroundImage: `url(${cfImage})` }}>
          <label htmlFor="contact-form-name" className="form-contact-label">
            <p className="form-contact-name">
              {translator("name", "capitalize_first")} {nameError.on && translator("error")}
            </p>
            <input
              type="text"
              name="name"
              className={`form-input ${nameError.on && "error"}`}
              id="contact-form-name"
              onChange={this.handleOnChange}
              defaultValue={name}
            />
          </label>
          <label htmlFor="contact-form-lastname" className="form-contact-label">
            <p className="form-contact-name">
              {translator("lastname", "capitalize_first")} {lastnameError.on && translator("error")}
            </p>
            <input
              type="text"
              name="lastname"
              className={`form-input ${lastnameError.on && "error"}`}
              id="contact-form-lastname"
              onChange={this.handleOnChange}
              defaultValue={lastname}
            />
          </label>
          <label htmlFor="contact-form-email" className="form-contact-label">
            <p className="form-contact-name">
              {translator("email", "capitalize_first")} {emailError.on && translator("error")}
            </p>
            <input
              type="email"
              name="email"
              className={`form-input ${emailError.on && "error"}`}
              id="contact-form-email"
              onChange={this.handleOnChange}
              defaultValue={email}
            />
          </label>
          <label htmlFor="contact-form-company" className="form-contact-label">
            <p className="form-contact-name">
              {translator("company_name", "capitalize_first")} {companyNameError.on && translator("error")}
            </p>
            <input
              type="text"
              name="companyName"
              className={`form-input ${companyNameError.on && "error"}`}
              id="contact-form-company"
              onChange={this.handleOnChange}
              defaultValue={companyName}
            />
          </label>
          <label htmlFor="contact-form-message" className="form-contact-label">
            <p className="form-contact-name">
              {translator("message", "capitalize_first")} {msgError.on && translator("error")}
            </p>
            <textarea
              name="msg"
              className={`form-input-wide ${msgError.on && "error"}`}
              id="contact-form-message"
              onChange={this.handleOnChange}
              defaultValue={msg}
            />
          </label>
          <input type="submit" className="form-submit" value={translator("submit", "capitalize_first")} />
        </form>
      </div>
    )
  }
}

import * as Icons from "../../../assets/icons"
import translator from "../../../assets/translations/translator"
import React from "react"

const switchLanguage = (lang) => {
  localStorage.setItem("language", lang)
  window.location.reload()
}

const data = [
  {
    id: "menu-item-1",
    key: "menu-item-1",
    url: "/home",
    name: translator("home", "capitalize_first"),
    Icon: Icons.Home,
    exact: true,
    submenu: [],
  },
  {
    id: "menu-item-1-5",
    key: "menu-item-1-5",
    url: "/about-us",
    name: translator("about_us", "capitalize_first"),
    Icon: Icons.AboutUs,
    exact: true,
    submenu: [],
  },
  {
    id: "menu-item-1-664",
    key: "menu-item-1-664",
    url: "/gallery",
    name: translator("gallery", "capitalize_first"),
    Icon: Icons.Photos,
    exact: true,
    submenu: [],
  },
  {
    id: "menu-item-2",
    key: "menu-item-2",
    url: "/contact",
    name: translator("contact", "capitalize_first"),
    Icon: Icons.Contact,
    exact: true,
    submenu: [],
  },
  {
    id: "menu-item-3",
    key: "menu-item-3",
    name: localStorage.getItem("language") === "pl" ?
      <React.Fragment>
        <img
          onClick={() => switchLanguage("en")}
          src='https://upload.wikimedia.org/wikipedia/en/thumb/a/ae/Flag_of_the_United_Kingdom.svg/1200px-Flag_of_the_United_Kingdom.svg.png'
          class={"flag"} />
      </React.Fragment> :
      <React.Fragment>
        <img
          onClick={() => switchLanguage("pl")}
          src='https://upload.wikimedia.org/wikipedia/en/thumb/1/12/Flag_of_Poland.svg/255px-Flag_of_Poland.svg.png'
          class={"flag"} />
      </React.Fragment>
    ,
    Icon: React.Fragment,
    submenu: [],
  },
]

export default data

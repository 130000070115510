import React from "react"

import Page from "../components/Page"
import translator from "../assets/translations/translator"
import images from "../assets/images/slider"
import Slider from "react-slick"

const Gallery = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    adaptiveHeight: true,
  }

  return (
    <Page title={translator("gallery")}>
      <h2 className="site-title">{translator("gallery")}</h2>
      <div style={{ paddingBottom: "40px" }}>
        <Slider {...settings}>
          {
            images.map(image => (
              <div className='slider-item' key={image.key}>
                <img src={image.url} className='g' />
              </div>
            ))
          }
        </Slider>

      </div>
    </Page>
  )
}

export default Gallery

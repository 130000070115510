import React from "react"
import Slider from "react-slick"

import Head from "./Head"
import Menu from "./Menu"
import Footer from "./Footer"
import innovationsImage from "../../assets/images/innowacje.jpg"
import innovationsImageEn from "../../assets/images/innowacjeEn.jpg"
import aboutUsImage from "../../assets/images/o-nas.jpg"
import { NavLink } from "react-router-dom"

class Layout extends React.Component {
  render() {
    const { children, title } = this.props

    return (
      <div className="site-wrapper">
        <Head title={title} />
        <Menu />
        <div className='slider-wrapper'>
          <Slider {...tilesSettings}>
            <NavLink to={"/"}>
              <div className='slider-item'>
                {localStorage.getItem("language") === "pl" ?
                  <img src={innovationsImage} alt='innovations image' className='slider-image h' />
                  :
                  <img src={innovationsImageEn} alt='innovations image' className='slider-image h' />
                }
              </div>
            </NavLink>
            <NavLink to={"/about-us"}>
              <div className='slider-item'>
                <img src={aboutUsImage} alt='about us image' className='slider-image h' />
              </div>
            </NavLink>
          </Slider>
        </div>
        <div className="site-section">{children}</div>
        <Footer />
      </div>
    )
  }
}


const tilesSettings = {
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
}

export default (Layout)

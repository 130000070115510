const defaultTranslations = {
  about_us: "About us",
  buy_now: "Buy now",
  client_office: "International Customers Sale Support",
  contact: "contact",
  contact_us: "Contact us",
  company_name: "Company name",
  email: "Email",
  error: " - cannot be empty",
  example_image: "Our photos",
  folder: "folder",
  gallery: "Gallery",
  home: "home",
  language: " ",
  lastname: "Lastname",
  message: "Message",
  mini_pdf: "Mini PDF",
  movies: "Movies",
  name: "Name",
  our_offer: "Our offer",
  pricing_catalog: "Pricing catalogue",
  range_of_activities: "Range of activities",
  submit: "Submit",
  tel: "Tel", text_range_of_activities: "Projecting and producing\n" +
    "professional engineer solutions\n" +
    "adjusted to each client individually.",
  q_led_pdf: "Q-LED PDF",
  unknown: "??????",

//  About us page
  about_us_text_1: "Q-Guar is a Polish brand that designs and manufactures specialized tools tailored to the needs of " +
    "various workplaces. We are based in Wroclaw, along with all laboratory and research facilities as well " +
    "as production lines. It is in the capital of Lower Silesia that we work to support entrepreneurship and " +
    "develop the potential of many companies.",
  about_us_text_2: `One of the most popular achievements of our company is the portable Q-LED system designed for use in:
    <li>industrial companies to illuminate cabins and production lines</li>
    <li>buses, wagons, locomotives and ships</li>
    <li>cabins, tanks and engines.</li>`,
  about_us_text_3: "Our products are perfectly made for uniformed services, fire rescue and rescue teams. The Q-LED system is suitable wherever there are power outages, bad weather conditions or an explosive environment. ",
  about_us_text_4: ` The structure of the system, made of high-quality materials, guarantees resistance to damage and the effects of strong chemicals, and the ATEX certificate granted to the system is a guarantee of using a safe source of lighting and thus ensuring appropriate working conditions. The Q-LED system is a block structure created with advanced  technologically  luminaire and a replaceable Q-POWER-BANK Li-Ion battery. The Q-LED luminaire is characterized by excellent light parameters, smooth power control, exceptional durability and a wide range of applications. The selected set is subject to any configuration in terms of the power range and the power supply method, and you can choose from the following versions:
    <li>Q-LED 60W - 10,000lm and Q-LED MINI - 10,000lm – standard</li>
    <li>Q-LED 120W - 20,000lm and Q-LED 240W - 40,000lm - available on request</li>`,
  about_us_text_5: `The power supply for all versions is:
    <li>replaceable battery 36V 11.6 Ah</li>
    <li>permanent power supply from 12V to 42V DC</li>
    <li>IP67 230V AC power supply.</li>`,
  about_us_text_6: "To maintain the mobility of lighting, we recommend mounting with our lightweight aluminum tripods with a spreading function and multi-functional Q-GRIP handles.",

// Home page
  home_1: "We have developed the Q-LED Portable System to meet the demand for professional lighting\n" +
    "equipment for workplaces in the heavy, oil, gas and mining industries. Our battery floodlights are\n" +
    "also perfect for lighting the area in uniformed services and rescue services, i.e. where there are often\n" +
    "problems with power supply, difficult conditions or an environment with an explosion hazard.\n" +
    "Therefore, the construction of the Q-LED is made of high-quality materials resistant to damage,\n" +
    "chemicals and confirmed by the ATEX certificate.",
  home_2: `Q-LED is a block structure based on a technologically advanced luminaire and a replaceable Q-POWER-BANK battery. We stand out with our own solutions in the range of activity in quick lighting setup, such as the Q-GRIP handle and the Q-TRIPOD tripod.
     <br/>Basic models:
     <li>Q-LED</li>
     <li>Q-LED MINI</li>
     <li>Q-LED STANDARD</li>
     <li>Q-LED MAX</li>
     Models available on request:
     <li>Q-LED 120W</li>
     <li>Q-LED 240W</li>
     Order setup:
     <li>Q-LED MAX + additional Q-LED luminaires</li>`,
  home_3: "The Q-LED luminaire designed by us is characterized by very high, eye-friendly parameters of the light\n" +
    "beam, smooth power regulation and long service life, thanks to which it has a wide range of\n" +
    "applications. It has proven its efficiency as linear lighting permanently installed inside and outside\n" +
    "buildings where there are difficult working conditions or explosion hazard. Designed for all kinds of\n" +
    "work zones and workplaces as small size lighting, resistant to damage, having the ability to be\n" +
    "powered by a battery, or from existing sources of direct or alternating current. We provide any\n" +
    "assembly system on request.",
  home_4: "Q-GUAR portable lighting is equipped with functional tripods. They can also be used without tripod.\n" +
    "Q-GRIP handle made of stainless steel with an adjustment range from 10 mm to 130 mm, which\n" +
    "allows you to quickly install the Q-LED kit vertically or horizontally. The unique design allows you to\n" +
    "grab any selected element of the environment. Q-GRIP is equipped with polyurethane overlays to\n" +
    "protect against surface damage.\n",
  home_5: "We also do not forget about sectors outside the broadly understood industry. All Q-LED models are\n" +
    "perfect for illuminating the work of uniformed and rescue services. To complete our offer, we have\n" +
    "\n" +
    "also created a specially designed set of Q-LED MINI portable lighting with a cover structure that\n" +
    "allows you to carry the device in your hand or on your back. Perfect for rescue actions.\n" +
    "Q-LED MINI has all the high parameters of the light beam of our industrial luminaires, does not\n" +
    "damage eyesight, is made of the highest quality materials and is completely waterproof.",
  home_6: "Q-POWER-BANK The most modern type of Li-ion battery with a capacity of 11.6 Ah and a rated\n" +
    "voltage of 36V, based on the safest Panasonic cells. Designed to power Q-LED lighting. The battery is\n" +
    "equipped with an electronic BMS management system with a number of protection against damage,\n" +
    "which at the same time extends the life of the cells up to 1000 cycles. The complete tightness of the\n" +
    "casing allows the battery to be used in harsh operating conditions and in an explosion hazard zone.\n" +
    "The product is ATEX certified. An additional 230V IP67 power supply is available for all types of Q-\n" +
    "GUAR lighting. Upon Client&#39;s request Q-POWER-BANK can be adjusted to power any electrical device.",
  home_7: "We are a Polish company based in Wrocław’s Technology Park in Wrocław. We have the access to\n" +
    "the most modern laboratory and scientific facilities, equipped with advanced instrumentation and\n" +
    "production lines, we can ensure that our products have the highest quality and the latest technical\n" +
    "solutions. We meet customer expectations, design and manufacture specialized tools adjusted to the\n" +
    "needs of different type of workplace.",
}


export default defaultTranslations

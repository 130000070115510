import React from "react"
import { v4 } from "uuid"

import Home from "../pages/Home"
import AboutUs from "../pages/AboutUs"
import Contact from "../pages/Contact"
import Gallery from "../pages/Gallery"

const mainRoutes = [
  {
    id: "route-1",
    path: "/",
    render: <Home />,
    exact: true,
    role: 0,
  },
  {
    id: "route-2",
    path: "/home",
    render: <Home />,
    exact: true,
    role: 0,
  },
  {
    id: "route-22",
    path: "/gallery",
    render: <Gallery />,
    exact: true,
    role: 0,
  },
  {
    id: "route-3",
    path: "/about-us",
    render: <AboutUs />,
    exact: true,
    role: 0,
  },
  {
    id: "route-4",
    path: "/contact",
    render: <Contact />,
    exact: true,
    role: 0,
  },
]

export default mainRoutes

const defaultTranslations = {
  about_us: "O nas",
  buy_now: "Kup teraz",
  client_office: "Biuro obsługi klienta",
  contact: "Kontakt",
  contact_us: "Napisz do nas",
  company_name: "Nazwa firmy",
  email: "Email",
  error: " - nie może być puste",
  example_image: "Nasze zdjęcia",
  folder: "folder",
  gallery: "Galeria",
  home: "Główna",
  language: " ",
  lastname: "Nazwisko",
  message: "Wiadomość",
  mini_pdf: "Mini PDF",
  movies: "Filmy",
  name: "Imię",
  our_offer: "Nasza oferta",
  pricing_catalog: "Katalog cen",
  range_of_activities: "Zakres działalności",
  submit: "Wyślij",
  tel: "Tel",
  text_range_of_activities: "Projektowanie oraz produkcja\n" +
    "profesjonalnych rozwiązań inżynieryjnych\n" +
    "dostosowanych do wyspecjalizowanych wymagań klienta.",
  q_led_pdf: "Q-LED PDF",
  unknown: "??????",

//  About us page
  about_us_text_1: "Q-Guar Sp. z o.o. to polska marka zajmująca się projektowaniem i produkcją specjalistycznych narzędzi dostosowanych do potrzeb różnych stanowisk pracy. Siedziba naszej firmy wraz z całym zapleczem laboratoryjnym i naukowym oraz liniami produkcyjnymi znajduje się we Wrocławiu. To właśnie w stolicy Dolnego Śląska działamy na rzecz wspierania przedsiębiorczości i rozwoju potencjału wielu firm.",
  about_us_text_2: `Jednym z najpopularniejszych osiągnięć naszej firmy jest przenośny system Q-LED przeznaczony do użytku w:
    <li>firmach przemysłowych do oświetlania kabin i obudów linii produkcyjnych</li>
    <li>przy montażach w autobusach, wagonach, lokomotywach i na statkach</li>
    <li>serwisach struktur naftowo gazowych, wodociągowych, energetycznych</li>
    <li>podczas akcji służb ratowniczych, mundurowych, straży pożarnej i wojska</li>`,
  about_us_text_3: "Oświetlenie Q-LED sprawdza się wszędzie tam, gdzie występują problemy z zasilaniem, ciężkie warunki lub środowisko zagrażające wybuchem. Konstrukcja systemu wykonana z wysokiej jakości materiałów gwarantuje odporność na uszkodzenia i działanie silnych środków chemicznych, a przyznamy systemowi certyfikat ATEX stanowi gwarancję zastosowania bezpiecznego źródła światła i tym samym zapewnienia odpowiednich warunków pracy.",
  about_us_text_4: `System Q-LED jest blokową konstrukcją powstającą dzięki wykorzystaniu zaawansowanej technicznie oprawy oświetleniowej oraz wymiennej baterii Q-POWER-BANK Li-Ion. Oprawa Q-LED cechuje się doskonałymi parametrami światła, płynną regulacją mocy, wyjątkową żywotnością oraz szerokim zakresem zastosowań. Wybrany zestaw podlega dowolnej konfiguracji pod względem zakresu mocy oraz sposobu zasilania, a do wyboru są wersje:
    <li>Q-LED 60W - 10 000lm i Q-LED MINI - 10 000lm - standardowe </li>
    <li>Q-LED 120W - 20 000lm i Q-LED 240W - 40 000lm - dostępne na zamówienie</li>`,
  about_us_text_5: `Zasilanie dla wszystkich wersji to:
    <li>wymienny akumulator 36V 11.6 Ah</li>
    <li>zasilanie stałe od 12V do 42V DC</li>
    <li>zasilacz IP67 230V AC.</li>`,
  about_us_text_6: "Metoda instalacji systemu Q-LED może być w każdym przypadku dostosowana do uniwersalnych wymagań Klienta. Dla zachowania mobilności oświetlenia rekomendujemy montaż z naszymi lekkimi aluminiowymi statywami z funkcją rozporową oraz wielofunkcyjnymi uchwytami Q-GRIP.",

// Home page
  home_1: "Przenośny System Q-LED opracowaliśmy wychodząc na przeciw zapotrzebowaniu na wyposażenie w profesjonalny sprzęt oświetleniowy stanowisk pracy w przemyśle ciężkim, naftowo-gazowym oraz górniczym. Nasze naświetlacze akumulatorowe sprawdzają się także doskonale jako oświetlenie pola akcji w służbach mundurowych i ratownictwie, czyli tam gdzie często występują problemy z zasilaniem, trudne warunki lub środowisko zagrażające wybuchem. Dlatego konstrukcja Q-LED wykonana jest z wysokiej jakości materiałów odpornych na uszkodzenie, środki chemiczne oraz potwierdzona certyfikatem ATEX.",
  home_2: "Q-LED to konstrukcja blokowa oparta na zaawansowanej technicznie oprawie oświetleniowej oraz wymiennej baterii Q-POWER-BANK. Wyróżniamy się własnymi rozwiązaniami w zakresie szybkiego montażu oświetlenia takimi jak uchwyt Q-GRIP i statyw Q-TRIPOD." +
    "<br/>Modele podstawowe:<br/>" +
    "<li>Q-LED</li>" +
    "<li>Q-LED MINI</li>" +
    "<li>Q-LED STANDARD</li>" +
    "<li>Q-LED MAX</li>" +
    "Modele dostępne na zamówienie:" +
    "<li>Q-LED 120W</li>" +
    "<li>Q-LED 240W</li>" +
    "Konfiguracja zamówienia:" +
    "<li>Q-LED MAX + dodatkowe oprawy Q-LED</li>",
  home_3: "Zaprojektowaną przez nas oprawę Q-LED cechują bardzo wysokie, przyjazne dla wzroku parametry strumienia światła, płynna regulacja mocy oraz długa żywotność dzięki czemu posiada ona szerokie spektrum zastosowań. Doskonale sprawdza się jako oświetlenie liniowe instalowane na stałe wewnątrz i na zewnątrz obiektów gdzie występują ciężkie warunki pracy lub zagrożenie wybuchem. Zbudowana z myślą o wszelkiego rodzaju strefach i stanowiskach pracy jako oświetlenie małych rozmiarów, odporne na uszkodzenie, posiadające możliwość zasilania z akumulatora, czy też z istniejących źródeł prądu stałego lub przemiennego. Zapewniamy dowolny system montażu na zamówienie.",
  home_4: "Oświetlenie przenośne Q-GUAR wyposażone jest w funkcjonalne statywy, ale można stosować je także bez ich użycia. Uchwyt Q-GRIP wykonany ze stali nierdzewnej z zakresem regulacji od 10 mm do 130 mm, który pozwala na błyskawiczne zainstalowanie zestawu Q-LED pionowo lub poziomo. Unikalna konstrukcja daje możliwość chwycenia za dowolnie wybrany element otoczenia. Q-GRIP wyposażony jest w poliuretanowe nakładki chroniące przed uszkodzeniem powierzchni.",
  home_5: "Nie zapominamy także o branżach spoza szeroko rozumianego przemysłu. Wszystkie modele Q-LED doskonale sprawdzają się do oświetlania pola akcji podczas pracy służb mundurowych i ratowniczych. Dla dopełnienia naszej oferty stworzyliśmy także specjalnie zaprojektowany zestaw oświetlenia przenośnego Q-LED MINI z konstrukcją pokrowca, która pozwala na noszenie urządzenia w ręce lub na plecach. Doskonale sprawdza się podczas akcji poszukiwawczych. Q-LED MINI posiada wszystkie wysokie parametry strumienia światła naszych opraw przemysłowych, nie niszczy wzroku, wykonany jest z najwyższej jakości materiałów i jest całkowicie wodoodporny.",
  home_6: "Q-POWER-BANK Najnowocześniejszy typ akumulatora Li-ion o pojemności 11,6 Ah i napięciu znamionowym 36V, oparty na najbezpieczniejszych ogniwach Panasonic. Przeznaczony do zasilania oświetlenia Q-LED. Bateria wyposażona jest w elektroniczny system zarządzania BMS z szeregiem zabezpieczeń przed uszkodzeniem, który jednocześnie wydłuża maksymalnie żywotność ogniw nawet do 1000 cykli. Całkowita szczelność obudowy pozwala stosować akumulator w ciężkich warunkach pracy oraz w strefie zagrożenia wybuchem. Produkt posiada certyfikat ATEX. Dla wszystkich rodzajów oświetlenia Q-GUAR dostępny jest dodatkowy zasilacz 230V IP67. Na zamówienie klienta umożliwiamy dostosowanie Q-POWER-BANK do zasilenia dowolnego urządzenia elektrycznego.",
  home_7: "Jesteśmy polską firmą z siedzibą we Wrocławskim Parku Technologicznym we Wrocławiu. Dzięki dostępowi do najnowocześniejszego zaplecza laboratoryjnego i naukowego, wyposażonego w zaawansowane oprzyrządowanie oraz linie produkcyjne możemy zapewnić, że nasze produkty posiadają najwyższą jakość i najnowsze rozwiązania techniczne. Wychodzimy na przeciw oczekiwaniom klienta, projektujemy i produkujemy specjalistyczne narzędzia dostosowane do potrzeb danego stanowiska pracy.",
}


export default defaultTranslations

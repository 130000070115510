import React from "react"

import translator from "../assets/translations/translator"
import { NavLink } from "react-router-dom"
import * as Icons from "../assets/icons"
import folderFilePl from "../assets/files/folder-q-led_pl.pdf"
import miniPdfFilePl from "../assets/files/q-led-mini_pl.pdf"
import qledPdfFilePl from "../assets/files/karta-techniczna-q-led_pl.pdf"
import priceFilePl from "../assets/files/cennik_pl.pdf"
import folderFileEn from "../assets/files/folder-q-led_en.pdf"
import miniPdfFileEn from "../assets/files/q-led-mini_en.pdf"
import qledPdfFileEn from "../assets/files/karta-techniczna-q-led_en.pdf"
import priceFileEn from "../assets/files/cennik_en.pdf"

const HomeRightSectionLinks = () => {
  let files = [folderFileEn, miniPdfFileEn, qledPdfFileEn, priceFileEn]
  if (localStorage.getItem("language") === "pl") {
    files = [folderFilePl, miniPdfFilePl, qledPdfFilePl, priceFilePl]
  }

  const fbpl_url = "https://www.facebook.com/Q-Guar-Innowacje-100822171909745"
  const fben_url = "https://www.facebook.com/Q-guar-106562914754546"
  const fburl = localStorage.getItem("language") === "pl" ? fbpl_url : fben_url

  return (
    <div className='home-links-lister'>
      <a href={fburl} target='_blank' className='home-link fb'>
        <img src={"https://icons-for-free.com/iconfiles/png/512/fb+icon-1320183899414660008.png"} />
        Facebook
      </a>
      <a href='https://www.youtube.com/channel/UCgXhtcO4luad8j-z_UGzgrg' target='_blank' className='home-link'>
        <Icons.Movie />
        {translator("movies", "uppercase")}
      </a>
      <a href={files[0]} className='home-link'>
        <Icons.Eye />
        {translator("folder", "uppercase")}
      </a>
      <a href={files[1]} className='home-link'>
        <Icons.Info />
        {translator("mini_pdf", "uppercase")}
      </a>
      <a href={files[2]} className='home-link'>
        <Icons.Info />
        {translator("q_led_pdf", "uppercase")}
      </a>
      <a href={files[3]} className='home-link'>
        <Icons.PricingDollar />
        {translator("pricing_catalog", "uppercase")}
      </a>
      <NavLink to='/contact' className='home-link'>
        <Icons.ShoppingCart />
        {translator("buy_now", "uppercase")}
      </NavLink>
    </div>
  )
}

export default HomeRightSectionLinks

import image1 from "./1.jpg"
import image2 from "./2.jpg"
import image3 from "./3.jpg"
import image4 from "./4.jpg"
import image5 from "./5.jpg"
import image6 from "./6.jpg"
import image7 from "./7.jpg"
import image8 from "./8.jpg"
import image9 from "./9.jpg"
import image10 from "./10.jpg"
import image11 from "./11.jpg"
import image12 from "./12.jpg"
import image13 from "./13.jpg"
import image14 from "./14.jpg"
import image15 from "./15.jpg"
import image16 from "./16.jpg"
import image17 from "./17.jpg"
import image18 from "./18.jpg"
import image19 from "./19.jpg"
import image20 from "./20.jpg"
import image21 from "./21.jpg"
import image22 from "./22.jpg"
import image23 from "./23.jpg"
import image24 from "./24.jpg"
import image25 from "./25.jpg"
// import image26 from "./26.jpg"
// import image27 from "./27.jpg"
// import image28 from "./28.jpg"
import image29 from "./29.jpg"
import image30 from "./30.jpg"
import image31 from "./31.jpg"
import image32 from "./32.jpg"
import image33 from "./33.jpg"
import image34 from "./34.jpg"
import image35 from "./35.jpg"
import image36 from "./36.jpg"

const images = [
  {
    key: "key",
    url: image1,
  },
  {
    key: "key",
    url: image2,
  },
  {
    key: "key",
    url: image3,
  },
  {
    key: "key",
    url: image4,
  },
  {
    key: "key",
    url: image5,
  },
  {
    key: "key",
    url: image6,
  },
  {
    key: "key",
    url: image7,
  },
  {
    key: "key",
    url: image8,
  },
  {
    key: "key",
    url: image9,
  },
  {
    key: "key",
    url: image10,
  },
  {
    key: "key",
    url: image11,
  },
  {
    key: "key",
    url: image12,
  },
  {
    key: "key",
    url: image13,
  },
  {
    key: "key",
    url: image14,
  },
  {
    key: "key",
    url: image15,
  },
  {
    key: "key",
    url: image16,
  },
  {
    key: "key",
    url: image17,
  },
  {
    key: "key",
    url: image18,
  },
  {
    key: "key",
    url: image19,
  },
  {
    key: "key",
    url: image20,
  },
  {
    key: "key",
    url: image21,
  },
  {
    key: "key",
    url: image22,
  },
  {
    key: "key",
    url: image23,
  },
  {
    key: "key",
    url: image24,
  },
  {
    key: "key",
    url: image25,
  },
  // {
  //   key: "key",
  //   url: image26,
  // },
  // {
  //   key: "key",
  //   url: image27,
  // },
  // {
  //   key: "key",
  //   url: image28,
  // },
  {
    key: "key",
    url: image29,
  },
  {
    key: "key",
    url: image30,
  },
  {
    key: "key",
    url: image31,
  },
  {
    key: "key",
    url: image32,
  },
  {
    key: "key",
    url: image33,
  },
  {
    key: "key",
    url: image34,
  },
  {
    key: "key",
    url: image35,
  },
  {
    key: "key",
    url: image36,
  },
]

export default images
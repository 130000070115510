const defaultTranslations = {
  about_us: "About us",
  buy_now: "Buy now",
  client_office: "Client office",
  contact: "contact",
  contact_us: "Contact us",
  company_name: "Company name",
  email: "Email",
  error: " - cannot be empty",
  folder: "folder",
  home: "home",
  language: "language",
  lastname: "Lastname",
  message: "Message",
  mini_pdf: "Mini PDF",
  movies: "Movies",
  name: "Name",
  our_offer: "Our offer",
  pricing_catalog: "Pricing catalog",
  range_of_activities: "Range of activites",
  submit: "Submit",
  tel: "Tel",
  text_range_of_activities: "Text",
  q_led_pdf: "Q-LED PDF",
  unknown: "??????",

//  About us page
  about_us_text_1: "Chociaż nie lubimy być szufladkowani można powiedzieć o nas, że należymy w pewnym sensie do “Kultury Twórców”.  Realizujemy się w tworzeniu z duchem rzemieślnika i uczeniu się poprzez działanie. W naszej działalności stawiamy przede wszystkim na jakość, a nie na ilość.",
  about_us_text_2: "Zajmujemy się projektowaniem i produkcją profesjonalnych rozwiązań inżynieryjnych dostosowanych do wyspecjalizowanych wymagań klienta. Naszą ofertę kierujemy do szeroko rozumianej branży przemysłowej, a także służb mundurowych:",
  about_us_text_3: "Współpracujemy z takimi firmami jak:",
  about_us_text_4: "Wieloletnie doświadczenie i rzetelne zaangażowanie we wspólne rozwiązywanie problemów, dziesiątki zrealizowanych projektów, pozwalają nam na jednoznaczne wskazanie nas jako wiarygodnego partnera. Nasza firma wciąż się rozwija dzięki profesjonalnej realizacji usług, a także doskonałej, wzajemnej komunikacji. W działaniu wykazujemy się szczególną dyspozycyjnością, kompetentnością oraz elastycznym podejściem do danego tematu.",
  about_us_ol_1: "Projektowanie, produkcja oraz serwisowanie zaawansowanego oświetlenia LED dla przemysłu, służb mundurowych i ratowniczych.",
  about_us_ol_2: "Usługi inżynieryjne takie jak:",
  about_us_ul_2_1: "inżynier na wypożyczenie,",
  about_us_ul_2_2: "usługi konstruktorskie,",
  about_us_ul_2_3: "tworzenie dokumentacji technicznej,",
  about_us_ul_2_4: "wdrażanie nowych projektów,",
  about_us_ul_2_5: "usługi konsultingowe,",
  about_us_ul_2_6: "wykonywanie audytów u dostawców,",
  about_us_ul_2_7: "ocena stanu technicznego narzędzi tłoczących oraz form + przeglądy narzędzi u klienta,",
  about_us_ol_3: "Usługi zewnętrznego utrzymania ruchu (mechanika i automatyka).",
  about_us_ul_3_1: "dostawa specjalnych elementów (matryc, stempli, płyt matrycowych / prowadzących / stemplowych) do narzędzi tłoczących, wykrojników, form z usługą magazynowania",
  about_us_ul_3_2: "cięcie laserem, plazmą, wodą, usługi spawania i napawania, druk 3D",
  about_us_ul_3_3: "toczenie, frezowanie CNC (obrabiarki 3 i 5 osiowe)",
  about_us_ul_3_4: "szlifowanie (płaszczyzn, na okrągło, bezkłowo)",
  about_us_ul_3_5: "Części automatyki: STEROWNIKI, PANELE OPERATORSKIE, KARTY, STYCZNIKOLOGIA 24V/230V, LOGO / STEROWNIKI I MODUŁY, SIMENS S5 ORAZ WIĘKSZOŚĆ ELEMENTÓW JUŻ WYCOFANYCH, ELEMENTY BEZPIECZEŃSTWA ( PRZEKAŹNIKI / WYŁĄCZNIKI / KRAŃCÓWKI / WYŁĄCZNIKI LINKOWE / STYCZNIKI / FALOWNIKI / SOFTSTARTY)",
  about_us_ol_4: "Magazynowanie części zamiennych (dostawa do klienta w 24h).",
  about_us_ol_5: "Tworzenie i modernizacja systemów automatyki:",
  about_us_ul_5_1: "sterowanie PLC, HMI, CNC (Siemens, Omron, Mitsubishi, Fanuc, Beckhoff)",
  about_us_ul_5_2: "RFID",
  about_us_ul_5_3: "roboty (Fanuc, Omron, Mitsubishi)",
  about_us_ul_5_4: "systemy bezpieczeństwa",
  about_us_ol_6: "Tworzenie i modyfikacje systemów informatycznych dla przemysłu (C#, VB, SQL itp).",
  about_us_ul_6_1: "archiwizacja danych, bazy danych",
  about_us_ul_6_2: "wizualizacja procesów przemysłowych, SCADA",
  about_us_ul_6_3: "systemy MES",
  about_us_ol_7: "Tworzenie i modernizacja aplikacji mobilnych Android, Java.",
}


export default defaultTranslations

import React from "react"
import { NavLink } from "react-router-dom"

const MenuItem = ({ Icon, name, url, submenu, action }) => {
  return (
    <div className='menu-item-wrapper'>
      {submenu && submenu.length !== 0 ? (
        <React.Fragment>
          <li className="menu-item" onClick={action}>
            <div className="menu-item-icon">
              <Icon />
            </div>
            <p className="menu-item-text">{name}</p>
          </li>
          <ol className="sub-menu">
            {submenu.map((subItem) => (
              <a href={subItem.url} key={subItem.id}>
                <li className="sub-menu-item" onClick={subItem.action}>
                  <div className="sub-menu-item-text">{subItem.name}</div>
                </li>
              </a>
            ))}
          </ol>
        </React.Fragment>
      ) : (
        url ? (
          <NavLink to={url}>
            <li className="menu-item" onClick={action}>
              <div className="menu-item-icon">
                <Icon />
              </div>
              <p className="menu-item-text">{name}</p>
            </li>
          </NavLink>
        ) : (
          <li className="menu-item" onClick={action}>
            <div className="menu-item-icon">
              <Icon />
            </div>
            <p className="menu-item-text">{name}</p>
          </li>
        ))}
    </div>
  )
}

export default MenuItem
